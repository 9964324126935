import React from 'react';
import Layout from "../components/Layout";
import Hero from "../components/Hero";
import withTrans from '../i18n/withTranslation';


const KnivesPage = ({ data, t }) => {

  const getContent = (translation) => {
    return { __html: `${t(translation)}` }
  }

  return (
    <>
      <Layout
        title={t('site.pages.KnivesPage.meta.title')}
        description={t('site.pages.KnivesPage.meta.description')}
        img={data.header.childImageSharp.fluid.src}
      >
        <Hero
          isDark={true}
          title={t('site.pages.KnivesPage.title')}
          subtitle={t('site.pages.KnivesPage.subtitle')}
          alt={t('site.pages.KnivesPage.headerAlt')}
          img={data.header.childImageSharp.fluid.src}
        />

        <div className="container my-5">
          <div dangerouslySetInnerHTML={getContent('site.pages.KnivesPage.content')}></div>

          <div className="my-5">
            <ul>
              {t('site.pages.KnivesPage.list').map((el, index) =>
                <li key={index} className="my-2">{el}</li>)}
            </ul>
          </div>
        </div>
      </Layout>
    </>
  )
}

export const query = graphql`
  query KnivesPage {
    header: file(relativePath: {eq: "hero-knives.jpg"}) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 1920) {
          src
        }
      }
    }
  }
`;

export default withTrans(KnivesPage);